import React, { useState, useEffect, useRef } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import Picker from 'emoji-picker-react'; 
import { defaultUrl } from "../../data/Images";
import "./Message.css";
import instance from "../../utils/axios";
import useUserStore from "../../store";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import GroupDetails from "./group-details";

var socket;
const Conversation = ({chatRoomId, resetSelectedChatRoomId}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [messages, setMessages] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [formattedMessages, setFormattedMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [ modal, setModal ] = useState(false)
    
  const user = useUserStore((state) => state.user);
  const conversationWrapperRef = useRef(null);
  const fileInputRef = useRef(null);
  
  useEffect(() => {
    socket = io("https://planetmuscle-fit-server.onrender.com");
    // socket = io("http://localhost:8080")
    socket.emit("setup", user);
    socket.on('connected', () => setSocketConnected(true))
    socket.on('typing', () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const messageReceivedHandler = (newMessageReceived) => {
      if (chatRoomId !== newMessageReceived.chatRoomId) {
        // setNotification(newMessageReceived);
      } else {
        const formattedMessage = formatMessages([newMessageReceived]);
        setFormattedMessages(prevState => [...prevState, ...formattedMessage]);
      }
    };
    socket.on('message received', messageReceivedHandler);
    return () => {
      socket.off('message received', messageReceivedHandler);
    };
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (conversationWrapperRef.current) {
      conversationWrapperRef.current.scrollTo(0, conversationWrapperRef.current.scrollHeight);
    }
  }, [formattedMessages]);

  const formatMessages = (messages) => {
    const formatted = [];
    let currentDate = null;
    messages.forEach((message) => {
      const messageDate = new Date(message.createdAt).toDateString();
      if (messageDate !== currentDate) {
        currentDate = messageDate;
        formatted.push({
          type: "divider",
          date: messageDate,
        });
      }
      formatted.push(message);
    });
    return formatted;
  };

  const getParticipantName = (id) => {
    const participant = participants.find(participant => participant._id === id);
    return participant ? participant.name : 'Unknown';
  };

  const handleEmojiClick = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    const updatedMessageContent = messageContent + emoji;
    setMessageContent(updatedMessageContent);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleMessageChange = (event) => {
    setMessageContent(event.target.value);
    if(!socketConnected) return;
    if(!typing) {
      setTyping(true)
      socket.emit('typing', chatRoomId);
    }
    const lastTypingTime = new Date().getTime();
    const timeout = 3000;
    setTimeout(() => {
      const timeNow = new Date().getTime();
      const timeDiff = timeNow - lastTypingTime;
      if(timeDiff >= timeout && typing) {
        socket.emit('stop typing', chatRoomId);
        setTyping(false);
      }
    }, timeout);
  };

  const handleFileChange = () => {
    fileInputRef.current.click();
  }
  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    try {
      const base64String = await convertFileToBase64(file);
      setMessageContent(base64String);
    } catch (error) {
      console.error('Error converting file to Base64:', error);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    socket.emit('stop Typing', chatRoomId);
    event.preventDefault();
    try {
      const response = await instance.post(`/api/user/sendMessage`, {
        chatRoomId: chatRoomId,
        content: messageContent,
        sender: user._id,
      });
      if (response.data.success) {
        setMessageContent('');
        const chatRoomId = response.data.data.savedMessage.chatRoomId;
        getMessages(chatRoomId);
      } else {
        console.error("Failed to send message:", response.data.message);
      }
      socket.emit("newMessage", response.data.data)
    } catch (error) {
      console.error("Error sending message:", error.message);
      toast.error(error.message);
    }
  };

  const getMessages = async (chatRoomId) => {
    if (!chatRoomId) return;
    try {
      const response = await instance.get(`/api/user/getConversationData/${chatRoomId}`);
      if (response.data.success) {
        const formatted = formatMessages(response.data.data.messages);
        setFormattedMessages(formatted);
        setMessages(response.data.data);
        setParticipants(response.data.data.participants);
        socket.emit('join chat', chatRoomId);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Get Message Error:", error);
      toast.error(error.message);
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); 
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12; 
    return `${hours}:${minutes} ${period}`;
  };  


  useEffect(() => {
    if (chatRoomId) {
      getMessages(chatRoomId);
    }
    // eslint-disable-next-line
  }, [chatRoomId]);  

  return (
    <div className={`conversation ${chatRoomId ? "active" : ""} `} id="conversation-1">
      { modal && (<GroupDetails groupName={messages.chatName} groupId={messages._id} participants={participants} closeModal={() => setModal(false)} profile={messages?.profilePicture?.secure_url} modal={modal} />)}
      {showEmojiPicker && (
        <div className="emoji-picker">
          <Picker onEmojiClick={handleEmojiClick} />
        </div>
      )}
      <div className="conversation-top">
        <Link to="/message">
          <button type="button" className="conversation-back" onClick={resetSelectedChatRoomId}>
            <ChevronLeftIcon />
          </button>
        </Link>
        <div className="conversation-user" >
          <img className="conversation-user-image" src={messages.profilePicture?.secure_url || defaultUrl} alt=""/>
          <div className="conversation-user-position">
            <div onClick={() => setModal(!modal)} className="conversation-user-name">
              {messages.chatName}
            </div>
            <div className="conversation-user-status online">
              {messages.isGroupChat ? "Group Chat" : "online"}
            </div>
          </div>
        </div>
      </div>
      <div className="conversation-main" ref={conversationWrapperRef}>
        <ul className="conversation-wrapper">
          {formattedMessages && formattedMessages.map((item, index) => {
            if (item.type === "divider") {
              return (
                <div className="conversation-divider" key={index}>
                  <span>{item.date === new Date().toDateString() ? "Today" : item.date}</span>
                </div>
              );
            } else {
              const senderParticipant = participants.find(participant => participant._id === item.sender);
              return (
                <li key={index} className={`conversation-item ${item.sender === user._id ? "" : "me"}`}>
                  <div className="conversation-item-side">
                    {senderParticipant && (
                      <img className="conversation-item-image"
                        src={senderParticipant.file?.secure_url || defaultUrl}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="conversation-item-content">
                    <div className="conversation-item-wrapper">
                      <div className="conversation-item-box">
                        <div className="conversation-item-text">
                          <h6 className="conversation-item-sender">{getParticipantName(item.sender)}</h6>
                          {item.content?.publicId ? 
                          <a href={item.content.secureUrl} className='message-a'  target="_blank"rel="noreferrer">
                            {item.content.secureUrl.match(/\.(png|jpe?g|webp|jpg|PNG|JPEG|JPG|WEBP)$/i) ? "Image" : 
                             item.content.secureUrl.endsWith(".pdf") ? "PDF" : 
                             item.content.secureUrl.endsWith(".mp3") ? "Audio" : 
                             item.content.secureUrl.endsWith(".mp4") ? "Video" : "File"}
                          </a> 
                          : <p>{item.content}</p>}
                          <div className="conversation-item-time">{formatTime(item.createdAt)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </div>
      <form className="conversation-form" style={{position: "relative"}} onSubmit={handleSubmit}>
        {isTyping? <div style={{position: "absolute", left: "0rem", top: "-1rem"}}>Loading...</div> : <></>}
        <div className="conversation-form-group">
          <EmojiEmotionsOutlinedIcon className="conversation-form-button emoji-icon" onClick={toggleEmojiPicker}/>
          <input
            type="text"
            required
            className="conversation-form-input"
            rows="1"
            placeholder="Send a Message..."
            value={messageContent}
            onChange={handleMessageChange}
          />
          <AttachFileOutlinedIcon className="conversation-form-record" onClick={handleFileChange}/>
          <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileInputChange} />
          <button
            type="submit"
            className="conversation-form-button conversation-form-submit"
          >
            <SendOutlinedIcon/>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Conversation;
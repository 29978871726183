import React, { useState, useEffect } from 'react'
import "./GoogleForm.css"
import instance from "../../utils/axios"
import { useNavigate, useLocation  } from 'react-router-dom';
import useUserStore from '../../store';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import toast from 'react-hot-toast';

const Userform = () => {
    const user = useUserStore((state) => state.user);
    const nav = useNavigate();
    const location = useLocation();
    const formId = location?.state?.formId;
    const planId = location?.state?.planId;

    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([{
        _id: "",
        questionText: "",
        questionType: "",
        options: [
            { optionText: "" },
        ],
        open: true,
        required: false,
    }]);
    const [formDetails, setFormDetails] = useState({
        formId: "",
        formName: "",
        formDescription: "",
        planName: "",
    });
    const [answer, setAnswer] = useState([]);
    function selectinput(que, id, type, e) {
        const { value } = e.target;
        const existingQuestion = questions.find((item) => item.questionText === que);
        if (existingQuestion) {
            const updatedOptions = existingQuestion.options.map((ques) =>
                ({ ...ques, optionText: value })
            );
            const updatedQuestions = questions.map((question) =>
                question.questionText === que
                ? {
                    ...question,
                    question: que,
                    options: updatedOptions,
                }
                : question
            );
            setQuestions(updatedQuestions);
            const existingAnswer = answer.find((item) => item.question === que);
            if (existingAnswer) {
                if (value.trim() === '') {
                    const filteredAnswer = answer.filter(item => item.question !== que);
                    setAnswer(filteredAnswer);
                } else {
                    const updatedAnswer = answer.map((item) =>
                        item.question === que
                        ? {
                            ...item,
                            options: updatedOptions,
                        }
                        : item
                    );    
                    setAnswer(updatedAnswer);
                }
            } else {
                const newAnswerEntry = {
                    question: que,
                    options: updatedOptions,
                    questionId: id,
                    questionType: type,
                };
                setAnswer([...answer, newAnswerEntry]);
            }
        }
    }        
    function handleDateInput(que, id, type, e) {
        const { value } = e.target;
        const existingAnswerIndex = answer.findIndex(item => item.question === que);
        const newAnswerEntry = {
            question: que,
            options: value,
            questionId: id,
            questionType: type,
        };
        if (existingAnswerIndex !== -1) {
            const updatedAnswer = [...answer];
            updatedAnswer[existingAnswerIndex] = newAnswerEntry;
            setAnswer(updatedAnswer);
        } else {
            setAnswer([...answer, newAnswerEntry]);
        }
    }
    function handleTimeInput(que, id, type, e) {
        const { value } = e.target;
        const existingAnswerIndex = answer.findIndex(item => item.question === que);
        const newAnswerEntry = {
            question: que,
            options: value,
            questionId: id,
            questionType: type,
        };
        if (existingAnswerIndex !== -1) {
            const updatedAnswer = [...answer];
            updatedAnswer[existingAnswerIndex] = newAnswerEntry;
            setAnswer(updatedAnswer);
        } else {
            setAnswer([...answer, newAnswerEntry]);
        }
    }
    function selectcheck(e, que, option, id, type) {
        const existingAnswerIndex = answer.findIndex(item => item.question === que);
        if (e.target.checked) {
            if (existingAnswerIndex !== -1) {
                const updatedOptions = [...answer[existingAnswerIndex].options, option];
                const updatedAnswer = [...answer];
                updatedAnswer[existingAnswerIndex].options = updatedOptions;
                setAnswer(updatedAnswer);
            } else {
                const newAnswerEntry = {
                    question: que,
                    options: [option],
                    questionId: id,
                    questionType: type,
                };
                setAnswer([...answer, newAnswerEntry]);
            }
        } else {
            if (existingAnswerIndex !== -1) {
                const updatedOptions = answer[existingAnswerIndex].options.filter(opt => opt !== option);
                const updatedAnswer = [...answer];
                updatedAnswer[existingAnswerIndex].options = updatedOptions;
                setAnswer(updatedAnswer);
                if (updatedOptions.length === 0) {
                    const filteredAnswer = updatedAnswer.filter(item => item.question !== que);
                    setAnswer(filteredAnswer);
                    console.log("All options unchecked. Updated answer:", filteredAnswer);
                }
            }
        }
    }
    function select(que, option, id, type) {
        const filteredAnswer = answer.filter(item => item.question !== que);
        const updatedAnswer = [...filteredAnswer, { question: que, options: option, questionId: id, questionType: type }];
        setAnswer(updatedAnswer);
    };
    function handleFileUpload(file, que, id, type) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const base64String = reader.result;
            const existingIndex = answer.findIndex(item => item.question === que);
            if (existingIndex !== -1) {
                const updatedAnswer = [...answer.slice(0, existingIndex), ...answer.slice(existingIndex + 1)];
                updatedAnswer.push({ question: que, options: base64String, questionId: id, questionType: type });
                setAnswer(updatedAnswer);
                console.log(updatedAnswer);
            } else {
                const updatedAnswer = [...answer, { question: que, options: base64String, questionId: id, questionType: type }];
                setAnswer(updatedAnswer);
                console.log(updatedAnswer);
            }
        };
    }    
    function selectFile(que, id, type, e) {
        const file = e.target.files[0];
        handleFileUpload(file, que, id, type);
    }
    function selectImage(que, id, type, e) {
        const file = e.target.files[0];
        handleFileUpload(file, que,id, type);
    }
    
    const getForm = async () => {
        try {
            const response = await instance.get("/api/user/getFormByPlanName", {
                params: {
                    userId: user._id,
                    formId,
                },
            });
            if (response.data.success) {
                const formData = response.data.data.forms;
                setQuestions(formData.questions);
                setFormDetails({
                    formId: formData._id,
                    formName: formData.formName,
                    formDescription: formData.formDescription,
                    planName: formData.planName,
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleSubmit = async () => {
        const requiredQuestions = questions.filter(question => question.required);
        const unansweredQuestions = requiredQuestions.filter(question => {
            const answeredQuestion = answer.find(ans => ans.question === question.questionText);
            return !answeredQuestion;
        });
        if (unansweredQuestions.length > 0) {
            const unansweredQuestionTexts = unansweredQuestions.map(question => question.questionText);
            toast.error(`Please answer the following required questions: ${unansweredQuestionTexts.join(', ')}`);
            return;
        }
        setLoading(true);
        try {
            const response = await instance.post("/api/user/submitForm",{
                answer: answer,
                formDetails: formDetails,
                userId: user._id,
                planId, 
            });
            if (response.data.success) {
                setLoading(false)
                toast.success(response.data.message);
                nav(-1)
            } else {
                toast.error(response.data.message)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log("submit response form: ", error);
            toast.error(error.message)
        } 
    };

    useEffect(() => {
        getForm();
        //eslint-disable-next-line
    }, [user._id]);

return (
    <div className="submit">
        <div className="submit_form">
            <div className="submit_form_section">
                <div className="submit_title_section">
                    <h3 className='submit-title-1' >{formDetails?.formName}</h3>
                    <h6 className='submit-title-2' >{formDetails?.formDescription}</h6>
                </div>
                {questions?.map((question, qindex) => (
                    <div className="user_form_questions">
                        <span className="user_form_span" >{qindex + 1}.  {question.questionText}</span>
                        {question?.options?.map((ques, index) => (
                            <div key={index} style={{ marginBottom: "5px" }}>
                                <div className='form-userForm'>
                                    <div className="form-check">
                                        {question.questionType !== "radio" ? (
                                            question.questionType !== 'text' ? (
                                                <label className='form-userform-label'>
                                                    {question.questionType === "file" && (
                                                        <input
                                                            type="file"
                                                            accept=".pdf"
                                                            className="form-check-input form-width"
                                                            required={question.required}
                                                            style={{ marginLeft: "5px", marginRight: "5px" }}
                                                            onChange={(e) => { selectFile(question.questionText, question._id, question.questionType, e) }}
                                                        />
                                                    )}
                                                    {(question.questionType === "date" || question.questionType === "time") && (
                                                        <input
                                                            type={question.questionType}
                                                            className="form-check-input form-width"
                                                            required={question.required}
                                                            style={{ marginLeft: "5px", marginRight: "5px" }}
                                                            onChange={question.questionType === "date" ? 
                                                                (e) => { handleDateInput(question.questionText, question._id, question.questionType, e) } :
                                                                (e) => { handleTimeInput(question.questionText, question._id, question.questionType, e) }
                                                            }
                                                        />
                                                    )}
                                                    {question.questionType === "image" && (
                                                        <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            className="form-check-input form-width"
                                                            required={question.required}
                                                            style={{ marginLeft: "5px", marginRight: "5px" }}
                                                            onChange={(e) => { selectImage(question.questionText, question._id,question.questionType, e) }}
                                                        />
                                                    )}
                                                    {!(["file", "image", "date", "time"].includes(question.questionType)) && (
                                                        <input
                                                            type={question.questionType}
                                                            name={qindex}
                                                            value={ques.optionText}
                                                            className="form-check-input "
                                                            required={question.required}
                                                            style={{ marginLeft: "5px", marginRight: "5px" }}
                                                            onChange={(e) => { selectcheck(e, question.questionText, ques.optionText,question._id,question.questionType) }}
                                                        />
                                                    )}
                                                    {ques.optionText} 
                                                </label>
                                            ) : (
                                                <label>
                                                    <input
                                                        type={question.questionType}
                                                        name={qindex}
                                                        value={ques.optionText}
                                                        className="form-check-input form-width"
                                                        required={question.required}
                                                        style={{ marginLeft: "5px", marginRight: "5px" }}
                                                        onChange={(e)=>{selectinput(question.questionText,question._id,question.questionType, e)}}
                                                    />
                                                </label>
                                            )
                                        ) : (
                                            <label>
                                                <input
                                                    type={question.questionType}
                                                    name={qindex}
                                                    value={ques.optionText}
                                                    className="form-check-input"
                                                    required={question.required}
                                                    style={{ marginLeft: "5px", marginRight: "5px" }}
                                                    onChange={() => { select(question.questionText, ques.optionText, question._id,question.questionType) }}
                                                />
                                                {ques.optionText}
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                ))
                }
                {questions && (
                    <div className="user_form_submit">
                        <button disabled={loading}
                            type="submit" onClick={handleSubmit}
                            className={`dash-btn ${loading ? "upload-btn" : ""}`}
                            >
                            {loading ? (
                                <AutorenewIcon className="loader-css" />
                            ) : (
                                <>Submit</>
                            )}
                        </button>
                    </div>
                )}
            </div>
        </div>
    </div>
)
}
export default Userform;
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import instance from '../../../utils/axios';
import useUserStore from '../../../store';

const Exercise = () => {

  const [ exercises, setExercises ] = useState([]);
  const user = useUserStore((state) => state.user )

  const getData = async () => { 
    try { 
      const response = await instance.get(`/api/user/getAllExercises?userId=${user._id}`);
      if ( response.data.success ){
        console.log(response.data.data); 
        setExercises(response.data.data)
      } else { 
        toast.error(response.data.message)
      }
    } catch ( error ) { 
      console.log("get exercise by week error: ", error.message);
      toast.error(error.message)
    }
  }

  useEffect(() => {  
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className='dashMembers' > 
      <div className='dashMembers-container' >
        <span className="dashboard-title">
          All Prescriptions
        </span>
      </div>
      <div className='showProduct'>
        { exercises?.map((week, index) => (
          <Link className='show-product Link' to={`/dashboard/exercises/${week.prescriptionId}`} key={index} > 
            <div className='show-right' >
                <div className="show-name">
                  <h3 className='show-title'> {index + 1}. {week?.planType} Plan Exercise </h3>
                  <span className='show-price'> Plan Time: {week?.planTime} Months </span>
                </div>
            </div>
            <div className="show-button">
                <button className='update-btn'>View</button>
             </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Exercise;
import React, { useEffect, useState } from "react";
import { Sets } from "../../../data/exercise.js";
import { Repetition } from "../../../data/exercise.js";
import data from "../../../data/api.json";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../utils/axios.js";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../css/Dashprescription.css";

const DaysPrescription = () => {
  const { dayId } = useParams();
  const navigate = useNavigate();
  const [heading, setHeading] = useState("");
  const [status, setStatus] = useState("");
  const [days, setDays] = useState([]);
  const [exerciseList, setExerciseList] = useState([
    {
      exerciseName: "",
      numberOfSets: 0,
      time: "",
      reps: 0,
      gap: '',
    },
  ]);

  const [filteredExerciseList, setFilteredExerciseList] = useState([]);

  const getData = async () => {
    try {
      const response = await instance.get(
        `/api/user/getIndividualDay/${dayId}`
      );
      if (response.data.success) {
        setDays(response.data.data);
        setExerciseList(
          response.data.data.exercises.map((exercise) => ({
            exerciseName: exercise.exerciseName,
            numberOfSets: exercise.numberOfSets,
            time: exercise.time,
            reps: exercise.reps,
            gap: exercise.gap,
          }))
        );
        setHeading(response.data.data.dayHeading);
        setStatus(response.data.data.status);  
      }
    } catch (error) {
      console.log("get prescription error:", error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (dayId) {
      getData();
    }
    // eslint-disable-next-line
  }, [dayId]);

  const addExercise = () => {
    setExerciseList((prevList) => [
      ...prevList,
      {
        exerciseName: "",
        numberOfSets: 0,
        time: "",
        reps: 0,
      },
    ]);
  };

  const handleRemove = (index) => {
    setExerciseList((prevList) => {
      const newList = [...prevList];
      newList.splice(index, 1);
      return newList;
    });
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setExerciseList((prevList) => {
      const newList = [...prevList];
      newList[index][name] = value;
      return newList;
    });
  };

  useEffect(() => {
    const exercises = data.map((item) => item.EXERCISE) || [];
    setFilteredExerciseList(exercises);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post(
        `/api/user/saveExerciseList/${dayId}`,
        { exerciseList, dayHeading: heading, status }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        navigate(-1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("create day exercise list error: ", error.message);
      toast.error(error.message);
    }
  };

  return (
    <div className="dashMembers">
      <div className="dashMembers-container">
        <h5 className="dashboard-title">Update {days.dayNumber}</h5>
      </div>
      <div className="dash-user">
        <div className="create-form-div">
                <label className="create-form-label" htmlFor="heading">Day Heading</label>
                <input 
                  type="text"
                  placeholder="Enter Day Heading"
                  required
                  className="create-form-input"
                  onChange={(e) =>setHeading(e.target.value)}
                  value={heading}
                />
        </div>      
        {exerciseList.map((exercise, index) => (
          <div className="prescription-day" key={index}>
            <div className="prescription-day-1">
              <span className="dash-user-container-h2">
                Exercise Number: {index + 1}{" "}
              </span>
              <DeleteIcon
                className="prescription-day-delete"
                onClick={() => handleRemove(index)}
              />
            </div>
            <div className="prescription-day-grid">
              <div className="create-form-div">
                <label className="create-form-label" htmlFor="exerciseName">
                  Exercise Name
                </label>
                <input
                  type="text"
                  name="exerciseName"
                  id="exerciseName"
                  required
                  className="create-form-input"
                  list="exercises"
                  value={exercise.exerciseName}
                  placeholder="Select Exercise"
                  onChange={(e) => handleChange(index, e)}
                />
                <datalist id="exercises">
                  {filteredExerciseList.map((exercise, index) => (
                    <option key={index} value={exercise} />
                  ))}
                </datalist>
              </div>
              <div className="create-form-div">
                <label className="create-form-label" htmlFor="">
                  Number of Sets{" "}
                </label>
                <select
                  className="create-form-input"
                  name="numberOfSets"
                  id="numberOfSets"
                  required
                  onChange={(e) => handleChange(index, e)}
                  value={exercise.numberOfSets}
                >
                  <option value=""> Select Number of Sets </option>
                  {Sets?.map((set) => (
                    <option value={set.value} key={set.value}>
                      {set.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="create-form-div">
                <label className="create-form-label" htmlFor="">
                  Repetitions{" "}
                </label>
                <select
                  name="reps"
                  id="reps"
                  required
                  className="create-form-input"
                  value={exercise.reps}
                  onChange={(e) => handleChange(index, e)}
                >
                  <option value=""> Select Number of Reps </option>
                  {Repetition?.map((set) => (
                    <option value={set.value} key={set.value}>
                      {set.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="create-form-div">
                <label className="create-form-label" htmlFor="">
                  Time{" "}
                </label>
                <input
                  className="create-form-input"
                  type="text"
                  name="time"
                  required
                  value={exercise.time}
                  onChange={(e) => handleChange(index, e)}
                />
              </div>
              <div className="create-form-div">
                <label className="create-form-label" htmlFor="">
                  Gap Between Sets{" "}
                </label>
                <input
                  className="create-form-input"
                  type="text"
                  name="gap"
                  required
                  value={exercise.gap}
                  onChange={(e) => handleChange(index, e)}
                />
              </div>
            </div>
          </div>        
        ))}
        <button className="prescription-btn-3 view-btn" onClick={addExercise}>
          <AddIcon className="show-response-modal-btn-icons" />
          Add Exercise ({exerciseList.length})
        </button>
        <div className="create-form-div">
          <label className="create-form-label" htmlFor="status">Day Status</label>
          <select className="create-form-input" onChange={(e) => setStatus(e.target.value)} value={status}>
            <option value="">Select Status</option>
            <option value="Partially Completed">Partially Completed</option>
            <option value="Completed">Completed</option>
            <option value="Pending">Pending</option>
          </select>
        </div>      
        <button onClick={handleSubmit} className="dash-btn view-btn">
          Save
        </button>
      </div>
    </div>
  );
};

export default DaysPrescription;

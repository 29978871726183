import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import ShowResponseModal from "./showResponseModal";
import AssignTest from "./assign-test";
import useUserStore from "../../../store";
import instance from "../../../utils/axios";

const ResponseForm = () => {
  const { id } = useParams();
  const user = useUserStore((state) => state.user);

  const [employee, setEmployee] = useState([]);
  const [assignedUserMap, setAssignedUserMap] = useState({});
  const [form, setForm] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [ planId, setPlanId ] = useState(null);

  const toggleShowModal = (formId, name) => {
    setSelectedFormId(formId);
    setCustomerName(name);
    setShowModal(!showModal);
  };

  const toggleShowTestModal = (formId, name, userId, isAssigned, planId) => {
    setSelectedFormId(formId);
    setPlanId(planId);
    setCustomerName(name);
    setShowTestModal(!showTestModal);
    setSelectedUserId(userId);
    setAssigned(isAssigned);
  };

  const closeTestModal = () => {
    setShowTestModal(!showTestModal);
    getData();
  };

  const getData = async () => {
    try {
      const response = await instance.get(`/api/user/getResponses/${id}`, {
        params: {
          uniqueId: user.uniqueId,


          role: user.role,
        },
      });
      if (response.data.success) {
        const filteredData = response.data.data;
        const rows = filteredData?.slice().reverse().map((data, index) => {
              const reversedIndex = response.data.data.length - index;
              return {
                id: index + 1,
                formId: data.formId,
                assignedTo: data.assignedTo,
                planId: data.planId,
                isAssigned: data.isAssigned,
                userId: data.userId,
                col1: reversedIndex,
                col2: data.user.name,
                col3: data.user.mobile,
                col4: data.user.email,
                col5: data.user.occupation || "N/A",
                col6: data.user.healthIssues || "N/A",
                col7: data.user.gymLocation || "N/A",
                col8: new Date(data.submittedAt).toLocaleDateString("en-IN") || "N/A",
                col9: data.assignedTo,
              };
            });
        setForm(rows);
        const assignedUserMap = {};
        response.data.data.forEach((user) => {
          assignedUserMap[user.formId] = user.assignedTo;
        });
        setAssignedUserMap(assignedUserMap);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("get response error: ", error);
      toast.error(error.message);
    }
  };

  const getName = async () => {
    const response = await instance.get("/api/user/usernames", {
      params: {
        userRole: user.role,
      },
    });
    if (response.data.success) {
      setEmployee(response.data.data);
    } else {
      toast.error(response.data.message);
    }
  };

  useEffect(() => {
    getData();
    getName();
    // eslint-disable-next-line
  }, []);

  const columns = [
    { field: "col1", headerName: "Index", width: 50 },
    { field: "col2", headerName: "Name", width: 150 },
    { field: "col3", headerName: "Mobile", width: 120 },
    { field: "col4", headerName: "Email", width: 200 },
    { field: "col5", headerName: "Occupation ", width: 100 },
    { field: "col6", headerName: "Health Issues", width: 120 },
    { field: "col7", headerName: "Gym Location", width: 100 },
    { field: "col8", headerName: "Form Filled On", width: 120 },
    {
      field: "col9",
      headerName: "Assign To",
      width: 180,
      renderCell: (params) => {
        const assignId = params.row.formId;
        const assignedTo = assignedUserMap[assignId] || "";
        const handleAssignChange = (selectedValue) => {
          setAssignedUserMap({
            ...assignedUserMap,
            [assignId]: selectedValue,
          });
          handleAssign(assignId, selectedValue);
        };
        return assignedTo ? (
          <div>{assignedTo}</div>
        ) : (
          <select
            value={assignedTo}
            onChange={(e) => handleAssignChange(e.target.value)}
          >
            <option value="">Select Employee name</option>
            {employee &&
              employee.map((run, index) => (
                <option key={index} value={run}>
                  {run}
                </option>
              ))}
          </select>
        );
      },
    },
    {
      field: "col10",
      headerName: "View ",
      width: 100,
      renderCell: (params) => {
        const formId = params.row.formId;
        const name = params.row.col2;
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => toggleShowModal(formId, name)}
            >
              View
            </div>
          </div>
        );
      },
    },
    {
      field: "col11",
      headerName: "Assign Testing",
      width: 150,
      renderCell: (params) => {
        const formId = params.row.formId;
        const name = params.row.col2;
        const userId = params.row.userId;
        const isAssigned = params.row.isAssigned;
        const planId = params.row.planId;
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => toggleShowTestModal(formId, name, userId, isAssigned, planId)}
            >
              {isAssigned ? "View Tests" : "Assign Test"}
            </div>
          </div>
        );
      },
    },
  ];
  const handleAssign = async (assignId, assignedTo) => {
    try {
      const response = await instance.put(`/api/user/updateResponseForm/${assignId}`,
        { assignedTo: assignedTo, assignId: assignId }
      );
      if (response.data.success) {
        toast.success("Response Form Assigned Successfully.");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while assigning the form.");
    }
  };

  const data = {
    rows: form,
    columns: columns,
  };

  return (
    <>    
      <DataGrid
      {...data}
      initialState={{
        ...data.initialState,
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      pageSizeOptions={[5, 10, 25]}
      />
      {showModal && (
      <ShowResponseModal
        name={customerName}
        closeModal={toggleShowModal}
        formId={selectedFormId}
      />
      )}
      {showTestModal && (
      <AssignTest
        userId={selectedUserId}
        name={customerName}
        planId={planId}
        closeTestModal={closeTestModal}
        formId={selectedFormId}
        update={assigned}
      />
      )} 
    </>
  );
};

export default ResponseForm;



import React, {useState, useEffect} from 'react'
import { toast } from "react-hot-toast";
import '../../css/DashUser.css'
import CloseIcon from "@mui/icons-material/Close"
import instance from '../../../utils/axios';

const ShowResponseModal = ({formId, closeModal, name}) => {

    const [formResponseData, setFormResponseData] = useState();

    const getFormData = async() =>{
        try{
            const response = await instance.get(`/api/user/getIndividualFormResponse/${formId}`)
            if(response.data.success) {
                setFormResponseData(response.data.data);
            }else {
                toast.error(response.data.message);
            }
        }catch(error) {
            console.log(error);
            toast.error("An Error Occurred.");
        }
    }

    useEffect(() => {
        getFormData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className='tnc-modal1' >
            <div className='tnc-modal-content1 show-response-modal'>
                <h4 className="show-response-modal-h4">{name}&apos;s Response </h4>
                <CloseIcon className="close-icon" onClick={closeModal} />
                { formResponseData?.responses?.map((data , value) => (
                    <div key={value} className='show-response-modal-container'>
                        <span className='show-response-modal-span'> {value + 1}. {data.question} </span>
                        { data.questionType === 'file' || data.questionType === 'image' ?  (
                            <div className='show-response-modal-div'>
                                <ul className='show-response-modal-ul'>
                                {data.options.map((option) => (
                                    <a href={option.secure_url} target='_blank' rel="noreferrer" className='show-response-modal-li' key={option._id}>
                                        Click Here
                                    </a>
                                ))}
                                </ul>
                            </div>
                        ) : (
                            <div  className='show-response-modal-div'>
                                <ul className='show-response-modal-ul'> 
                                {data.options.map((option) => (
                                    <li className='show-response-modal-li' key={option._id}>
                                        Ans: {option.optionText ? (
                                            option.optionText
                                        )  : option}
                                    </li>
                                ))}
                                </ul>
                            </div>            
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShowResponseModal;
